@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #dfdeed 1.84%, #e6e8f3 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #101112;
  --color-footer : #0F1B49;
  --color-about: #84a0bb;
  --color-text: #c7c7c7;
  --color-subtext: #f1cac1;
}