* {
    box-sizing: border-box;
}

.pept__typeHeader {
    display: flex;
    flex-direction: column;
}

.pept__typeHeader-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.pept__typeHeader-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.03em;
}

.pept__typeHeader-content h2 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: -0.03em;
    color: #fff;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.pept__typeHeader-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.pept__typeHeader-content a {
    cursor: pointer;
    color: #83c5fe;
}

.pept__typeHeader-content a:hover {
    text-decoration: underline;
}

.pept__typeHeader-content p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; 
}

.dropdown-container {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    
}

.pept__typeHeader-content ul {
    list-style-position: inside; 
    padding-left: 0;
    margin-left: 0;
}

.pept__typeHeader-content ul li {
    margin-bottom: 0.5em; 
    font-family: var(--font-family);
    color: var(--color-text);
}

.document-links {
    display: flex;
    flex-direction: column;
}
  
  .document-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .document-item {
    margin-bottom: 2em;
    width: 100%;
}

.document-item iframe {
    width: 100%;
    height: 650px; 
    width: 500px;
    justify-content: center;
}
  
  .document-links a {
    display: inline-block;
    margin: 1em 0;
    color: #4056e6;
    text-decoration: none;
  }
  
  .document-links a:hover {
    text-decoration: underline;
  }
  
  .document-links iframe {
    border: none;
    margin-top: 1em;
  }

  .documents-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 20px;
  }

  .document-links h3 {
    font-family: var(--font-family);
    color: #fff;
    padding-top: 1rem;
  }

  .document-header h4 {
    font-family: var(--font-family);
    color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

@media screen and (max-width: 1050px) {
    .pept__typeHeader {
        flex-direction: column;
    }

    .pept__typeHeader-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 768px) {
    .video-content {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .pept__typeHeader h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .pept__typeHeader p {
        font-size: 16px;
        line-height: 24px;
    }

    .document-item iframe {
        height: 500px; 
        width: 350px;
        justify-content: center;
    }
}

@media screen and (max-width: 490px) {
    .pept__typeHeader h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .pept__typeHeader p {
        font-size: 14px;
        line-height: 24px;
    }

    .document-item iframe {
        height: 450px; 
        width: 300px;
        justify-content: center;
    }
}