.pept__icdHeader {
    display: flex;
    flex-direction: column;
}

.pept__icdHeader-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.pept__icdHeader-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.03em;
}

.pept__icdHeader-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.pept__icdHeader-content a {
    cursor: pointer;
    color: #4056e6;
}

.pept__icdHeader-content a:hover {
    text-decoration: underline;
}

.pept__icdHeader-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pept__videosIcd {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 1rem;
}

.video-wrapperIcd {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    margin-right: auto;
}

.video-contentIcd iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-contentIcd {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
}

.video-wrapperIcd h2 {
    font-size: 1.2em;
    padding: 0.5em 0;
}

.title-wrapperIcd {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--font-family);
    color: #fff;
}

.title-wrapperIcd a {
    position: relative;
    bottom: -4px;
}

@media screen and (max-width: 1050px) {
    .pept__icdHeader {
        flex-direction: column;
    }

    .pept__icdHeader-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .pept__icdHeader h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .pept__icdHeader p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .pept__icdHeader h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .pept__icdHeader p {
        font-size: 14px;
        line-height: 24px;
    }

    .video-wrapperIcd {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        width: 70%;
        margin-right: auto;
    }
}