.pept__referenceHeader {
    display: flex;
    flex-direction: column;
}

.pept__referenceHeader-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.pept__referenceHeader-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.03em;
}

.pept__referenceHeader-content h2 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 30px;
    line-height: 75px;
    letter-spacing: -0.03em;
    color: #fff;
}

.pept__referenceHeader-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.pept__referenceHeader-content a {
    cursor: pointer;
    color: #4056e6;
}

.pept__referenceHeader-content a:hover {
    text-decoration: underline;
}

.pept__referenceHeader-content p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; 
}

@media screen and (max-width: 1050px) {
    .pept__referenceHeader {
        flex-direction: column;
    }

    .pept__referenceHeader-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .pept__referenceHeader h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .pept__referenceHeader p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .pept__referenceHeader h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .pept__referenceHeader p {
        font-size: 14px;
        line-height: 24px;
    }
}

.pept__reference-cards {
    padding-top: 5rem;
}