.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-bg);
    color: white;
    text-decoration: none;
    height: 200px; 
    font-family: var(--font-family);
    text-align: center;
  }
  
  @media screen and (max-width: 600px) {
    .card-grid {
      grid-template-columns: 1fr;
    }
  
    .card {
      padding: 10px;
    }
  }