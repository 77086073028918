.pept__header {
    display: flex;
}

.pept__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.pept__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.03em;
}

.pept__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.pept__header-content a {
    cursor: pointer;
    color: #4056e6;
}

.pept__header-content a:hover {
    text-decoration: underline;
}

.pept__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pept__header-image img {
    width: 80%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 1050px) {
    .pept__header {
        flex-direction: column;
    }

    .pept__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .pept__header h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .pept__header p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .pept__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .pept__header p {
        font-size: 14px;
        line-height: 24px;
    }
}