.pept__peptHeader {
    display: flex;
    flex-direction: column;
}

.pept__peptHeader-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.pept__peptHeader-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.03em;
}

.pept__peptHeader-content h2 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 30px;
    line-height: 75px;
    letter-spacing: -0.03em;
    color: #fff;
}

.pept__peptHeader-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.pept__peptHeader-content a {
    cursor: pointer;
    color: #4056e6;
}

.pept__peptHeader-content a:hover {
    text-decoration: underline;
}

.pept__peptHeader-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pept__videos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 1rem;
}

.video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    text-align: left; 
    width: 50%; 
    margin-right: auto; 
}

.video-content {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.video-content iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-wrapper h2,
.video-wrapper a {
    font-size: 1.2em;
    padding: 0.5em 0;
    font-family: var(--font-family);
    color: #fff;
}

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.title-wrapper a {
    position: relative;
    bottom: -4px;
}

.pept__peptHeader-content p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; 
}

.dropdown-container {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    
}

#video-select {
    height: 40px; 
    width: 400px; 
    margin-left: 10px; 
    color: #fff;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;

    font-family: var(--font-family);
    color: #000000;
    font-size: 18px;
    font-weight: 400;
}

#video-select option {
    padding-left: 30px;
}

label {
    color: #fff;
    display: block;
    margin-bottom: 10px;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 400;
}

@media screen and (max-width: 1050px) {
    .pept__peptHeader {
        flex-direction: column;
    }

    .pept__peptHeader-content {
        margin: 0 0 3rem;
    }

    .video-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        text-align: left; 
        width: 100%; 
        margin-right: auto; 
    }
}

@media screen and (max-width: 768px) {
    .video-content {
        width: 100%;
    }

    .video-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        text-align: left; 
        width: 100%; 
        margin-right: auto; 
    }
}

@media screen and (max-width: 650px) {
    .pept__peptHeader h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .pept__peptHeader h2 {
        font-size: 24px;
        line-height: 40px;
    }

    .pept__peptHeader p {
        font-size: 16px;
        line-height: 24px;
    }

    .video-content iframe {
        width: 90%;
        height: 60vh; 
    }

    #video-select {
        height: 40px; 
        width: 300px; 
        margin-left: 10px; 
        color: #fff;
        display: block;
        margin-bottom: 10px;
        border-radius: 5px;
    
        font-family: var(--font-family);
        color: #000000;
        font-size: 14px;
        font-weight: 400;
    }

    .video-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        text-align: left; 
        width: 100%; 
        margin-right: auto; 
    }
}

@media screen and (max-width: 490px) {
    .pept__peptHeader h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .pept__peptHeader h2 {
        font-size: 18 px;
        line-height: 32px;
    }

    .pept__peptHeader p {
        font-size: 14px;
        line-height: 24px;
    }

    #video-select {
        height: 30px; 
        width: 200px; 
        margin-left: 10px; 
        color: #fff;
        display: block;
        margin-bottom: 10px;
        border-radius: 5px;
    
        font-family: var(--font-family);
        color: #000000;
        font-size: 14px;
        font-weight: 400;
    }

    .video-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        text-align: left; 
        width: 100%; 
        margin-right: auto; 
    }
    
}